<template>
	<div class="eye" :class="{hide}"></div>
</template>

<script>
export default {
  name: 'PasswordHide',
  props: {
    hide: Boolean
  },
  data(){
    return {
      
    }
  },
  methods:{
    doChanges(){
      this.random = Math.random();   
    }
  } 
}
</script>

<style scoped lange="less">
.eye{
	
}
</style>
