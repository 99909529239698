<template>
  <div id="Page-User-Login" class="w720">
		<!-- <div class="title-h2 _en">LOG IN</div> -->
		<div class="title-h1 ">{{L("登录")}}</div>
		<div class="mt-20"></div>
		<div class="form-box login">
			<div class="form-item e-mail" :class="{error:!formRule.email}">
				<div class="label">{{L("电子邮箱")}}<span>*</span></div>
				<div class="input"><input placeholder="E-Mail" type="text" v-model="formData.email" @change="checkEmail" ref="email" name="username" autocomplete="off"/></div>
			</div>
			<div class="form-item password" :class="{error:!formRule.password}">
				<div class="label">{{L("密码")}}<span>*</span></div>
				<div class="input"><input placeholder="Password" :type="passwordHide?'password':'text'" v-model="formData.password" @change="checkPassword" ref="password" name="password" autocomplete='new-password'/><PasswordHide @click.native="toggleEye('passwordHide')" :hide="passwordHide"  /></div>
			</div>
			<div class="form-item verification-code" :class="{error:!formRule.verificationCode}">
				<div class="label">{{L("验证码")}}<span>*</span></div>
				<div class="input">
					<input placeholder="Verification Code" type="text" v-model="formData.verificationCode" @change="checkCode" @keyup.enter="doSubmit" ref="verificationCode" name="verificationCode" maxlength="4" autocomplete="off"/>
					<VerificationCode ref="VerificationCode" type="FRONT_DESK_USER_LOGIN"/>
				</div>
			</div>
			<div class="form-tips">
				<p>{{L("*号为必填项")}}</p>
			</div>
			
			<div class="form-btn">
				<div class="flex between">
					<div><span class="button dark" :class="{loading}" @click="doSubmit">{{L("登录")}}</span></div>
					<div><span class="button" @click="$root.toPage('/User/Forget')">{{L("忘记密码")}}</span><span class="button" @click="$root.toPage('/User/Register')">{{L("注册账号")}}</span></div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import VerificationCode from '/src/components/VerificationCode.vue';
import PasswordHide from '/src/components/PasswordHide.vue';
export default {
  name: 'Page-User-Login',
  components: {
    VerificationCode,
		PasswordHide
  },
  data(){
    return {
			formRule:{
				email:true,
				password:true,
				verificationCode:true,
			},
			formData:{
				email:"",
				password:"",
				verificationCode:""
			},
			passwordHide:true,
			repasswordHide:true,
			loading:false
    };
  },
  async mounted () {
	
  },
  methods: {
		toggleEye(target){
			this[target] = !this[target];
		},
		checkEmail(){
			if(!this.$root.checkEmail(this.formData.email)){
				this.formRule.email = false;
				this.$refs.email.focus();
				return false;
			}
			this.formRule.email = true;
			return true;
		},
		checkPassword(){
			if(!this.formData.password || this.formData.password.length < 6){
				this.formRule.password = false;
				this.$refs.password.focus();
				return false;
			}
			this.formRule.password = true;
			return true;
		},
		checkCode(){
			if(!this.$root.checkCode(this.formData.verificationCode)){
				this.formRule.verificationCode = false;
				this.$refs.verificationCode.focus();
				return false;
			}
			this.formRule.verificationCode = true;
			return true;
		},
		checkAll(){
			return this.checkEmail() && this.checkPassword() && this.checkCode();
		},
		
		doSubmit(){
			if(!this.checkAll()){return false;}
			
			this.loading = true;
			
			var formData = {...this.formData};
			this.request.post('/api/v1/frontDeskUser/login',formData).then(res=>{
				console.log(res);
				
				this.loading = false;
				if(res){
					alert(this.L("登录成功"));
					if(res.token && res.email){
					  window.localStorage.setItem("token",res.token);
					  window.localStorage.setItem("email",res.email);
					  window.localStorage.setItem("userData",JSON.stringify(res));
					  this.request.defaults.headers.common["token"] = res.token;
					}
					window.location.reload()
				} else {
					this.$refs.VerificationCode.doChanges();
				}
			});
		}
  }
}
</script>

<style scoped lang="less">
.form-box.login{
	width:720px;
	margin:0 auto;
	
}
</style>