<template>
	<div>
		<img @click="doChanges" class="verification-code" v-if="img" :src="img"/>
		<span @click="doChanges">{{L("换一换")}}</span>
	</div>
</template>

<script>
	// FRONT_DESK_USER_REGISTER 前台注册,
	// FRONT_DESK_USER_LOGIN 前台登录,
	// FRONT_DESK_USER_RETRIEVE_PASSWORD 前台找回密码)
export default {
  name: 'VerificationCode',
  props: {
    type: String
  },
  data(){
    return {
      url:(process.env.NODE_ENV == 'product'?"/":"http://apd-web.sizne.net/")+"api/v1/frontDeskUser/getVerificationCode?type="+this.type,
			random:Math.random(),
			img:null
    }
  },
	async mounted(){
		this.loadImg();
	},
	watch:{
	},
  methods:{
    doChanges(){
      this.random = Math.random();  
			this.loadImg();
    },
		
		async loadImg(){
			var img = await this.request.get("/api/v1/frontDeskUser/getVerificationCode?type="+this.type+"&random="+this.random,{ responseType: 'blob'});
			let reader = new FileReader()
			reader.onload = (e) => {
			   this.img = e.target.result
			 }
			reader.readAsDataURL(img)
		}
  } 
}
</script>

<style scoped>

</style>
